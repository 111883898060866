console.log("<Loading.ts>");



var _isHiding = false,
  _resetNeed = false,
  $doms: any = {};

const weight =
{
  base: 10,
  image: 60,
  data: 30
};

let _imageProgress = 0,
  _dataProgress = 0;

var self =
{
  init: () =>
  {
    // $doms.container = $("#loading").css("visibility", "visible");

    // self.start();

    let dom = document.getElementById("loading");
    dom.style.visibility = "visible";

    document.body.setAttribute("ready", "true");
  },

  start: () =>
  {
    $doms.container = $("#loading");

    $doms.bar = $doms.container.find(".bar");

    ///@ts-ignore
    $('body').waitForImages
      ({
        finished: function ()
        {
        },
        each: function (loaded: any, count: any, success: any)
        {
          // _imageProgress = parseInt(String((loaded+1) / count * weight.image));
          _imageProgress = (loaded + 1) / count * weight.image;
          self.update();
        },
        waitForAll: true
      });

  },

  updateDataProgress(progress: number)
  {
    _dataProgress = progress;
    self.update();
  },

  update()
  {
    let percent = weight.base + _imageProgress + weight.data*_dataProgress;
    // console.log(percent);

    $doms.bar.css("width", percent + "%");

    // $loadingText.text("Loading ... "+percent+"%");
  },


  show: function (cb?: Function)
  {
    if (!_isHiding) return;
    _isHiding = false;

    $("body").append($doms.container);

    var tl = gsap.timeline();
    tl.to($doms.container, .5, { duration: .25, opacity: 1, ease: "power1.out" });
    tl.add(function ()
    {
      _resetNeed = true;
      if (cb) cb.call(null);
    });
  },

  hide: function (cb?: Function)
  {
    if (_isHiding) return;
    _isHiding = true;

    $doms.container.css("background-color", "transparent");

    $doms.container.toggleClass("hide-mode", true);

    setTimeout(function ()
    {
      if (cb) cb.call(null);
      $doms.container.detach();
    }, 400);

  }
};

// self.init();

export default self;


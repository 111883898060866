import { Emitter } from "strict-event-emitter";
import { ScrollListener } from "@ts/sframe/ScrollListener";
import gsap from "gsap";

type PageState = {
  $page: JQuery
  scrollTop: number
  resetScrollTopZero: boolean
}

let _pageDic: {[name:string]: PageState} = 
{

};

let _isLocking = false;


export default class PageManager
{
  private static _currentPageName: string = "index";

  static getCurrentPageName()
  {
    return this._currentPageName;
  }

  static emitter: Emitter<{
    beforePageIn: [newPage?: string, oldPage?: string], 
    beforePageTween: [newPage?: string, oldPage?: string], 
    afterPageIn: [newPage?: string, oldPage?: string],
    beforePageOut: [newPage?: string, oldPage?: string],
    afterPageOut: [newPage?: string, oldPage?: string],
  }>;

  static init()
  {
    this.emitter = new Emitter();
  }

  static addPage(name: string, $root: JQuery, scrollTop: number = 0, resetScrollTopZero: boolean = false)
  {
    _pageDic[name] = {$page: $root, scrollTop: scrollTop, resetScrollTopZero: resetScrollTopZero};
  }

  static getPageRoot(name: string)
  {
    return _pageDic[name];
  }

  static loadPage(name: string, filepath: string)
  {
    return new Promise((resolve)=>
    {
      let $elem = $(`
      <div id="page-content" page="work-detail">
        <div id="layout"></div>
        <div id="section-wrapper">
        </div>
      </div>`);

      $elem.find("#section-wrapper").load(filepath, (responseTxt, statusTxt, xhr)=>
      { 
        PageManager.addPage(name, $elem, 0, true);
        resolve(true);
      });
    });
    
  }

  static toPage(name: string)
  {
    return new Promise((resolve)=>
    {
      if(name === this._currentPageName || _isLocking){
        resolve(false);
        return;
      } 

      _isLocking = true;
  
      let oldPageName = this._currentPageName;
      let oldPageState = _pageDic[oldPageName];
  
      this._currentPageName = name;
  
      let pageState = _pageDic[name];
      // let $page = _pageDic[name];
      if(!pageState) throw new Error(`page name: [${name} is not exist]`);
  
      let $oldPage = $("#page-content"),
        $container = $("#page-root>.scroll-wrapper");
  
      this.emitter.emit("beforePageOut", name, oldPageName);
      oldPageState.scrollTop = oldPageState.resetScrollTopZero? 0: $(window).scrollTop();

      let tl = gsap.timeline();
      tl.to($oldPage, {duration: .3, opacity: 0, ease: "power1.out"});
      tl.add(()=>
      {
      
        $oldPage.detach();
        // $("#invisible-container").append($oldPage);
        this.emitter.emit("afterPageOut", name, oldPageName);
    
        this.emitter.emit("beforePageIn", name, oldPageName);

        pageState.$page.css("opacity", 0);
        
        $container.append(pageState.$page);
        this.emitter.emit("beforePageTween", name, oldPageName);
        $(window).scrollTop(pageState.scrollTop);
      });

      tl.to(pageState.$page, {duration: .3, opacity: 1, ease: "power1.in"});

      tl.add(()=>
      {
        _isLocking = false;
        this.emitter.emit("afterPageIn", name, oldPageName);
        resolve(true);
      });

    });
  }
}
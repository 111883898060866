import ComponentBase from "@components/ComponentBase";
import { ComponentElem } from "@components/interfaces";

export default class RwdPicture extends ComponentBase
{
  static generate(pcSrc: string = "", mobileSrc: string = "", altText?: string)
  {
    let $elem = 
    $(`
    <picture component="rwd-picture" lazy-loading="manual">
      <source media="(max-width: 640px)" data-srcset="${mobileSrc}">
      <img data-src="${pcSrc}" draggable="false">
    </picture>
    `);

    let result = new RwdPicture($elem[0]);
    result.setAlt(altText);

    return result;
  }

  manualLoaded: boolean = true;

  constructor(elem: ComponentElem)
  {
    super(elem);

    this.manualLoaded = (this.$root.attr("lazy-loading") === "manual")? false: true;
  }

  setDataSrc(src: string, target: "pc" | "mobile" = "pc")
  {
    let img = this.$root.find(`img`)[0],
    source = this.$root.find(`source[media="(max-width: 640px)"]`)[0];

    if(target === "pc")
    {
      img.dataset.src = src;
    }
    else
    {
      source.dataset.srcset = src;
    }
  }

  setAlt(altText?: string)
  {
    if(!altText) return;

    let img = this.$root.find(`img`)[0];
    img.setAttribute("alt", altText);
  }

  load(onload?: Function)
  {
    let img = this.$root.find(`img`)[0],
    $souces = this.$root.find("source");

    $.each($souces, (index, sourceElem)=>
    {
      sourceElem.setAttribute("srcset", sourceElem.dataset["srcset"]);
    });

    img.src = img.dataset.src;

    img.onload = ()=>
    {
      // console.log("image loaded");
      // console.log(img.currentSrc);

      this.$root.attr("lazy-loading", "done");

      this.manualLoaded = true;
      
      if(onload) onload.call(null);

      img.onload = null;
    }
  }
}
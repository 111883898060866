import ViewportRoot, { getViewportName, ViewportIndex } from "./sframe/ViewportRoot";
import ApiProxy from "./ApiProxy";

enum StyleModes
{
  mobie = 0,
  pc = 1,
  default = 1
}

export enum Direction
{
  TopLeft = 7,
  Top = 8,
  TopRight = 9,
  Left = 4,
  Center = 5,
  Right = 6,
  BottomLeft = 1,
  Bottom = 2,
  BottomRight = 3
}

const _localSetting =
{
  testEnv: "local",
  useApiSchemaData: true,
  logSendingData: false,
  apiPath: "./static/api-data/",
  apiExt: ".json",
};

const _testSetting =
{
  testEnv: "hikaru-test",
  useApiSchemaData: true,
  logSendingData: false,
  apiPath: "./static/api-data/",
  apiExt: ".json"
};


export default class GS
{
  static setting = {
    testEnv: "",
    useApiSchemaData: true,
    logSendingData: false,
    
    apiPath: "./static/api-data/",
    apiExt: ".json"
    // apiPath: "/api/frontend/",
    // apiExt: ""
  };

  static MOBILE_WIDTH = 640;
  static MIDDLE_WIDTH = 640;
  static MAX_WIDTH = 1920;

  static EM_TO_PX = 20;

  static reviewMaxRating: number = 5;

  static search = 
  {
    keywordMaxLength: 20,
    pageSize: 10,
    getQueryUrl: (queryString: string)=>
    {
      return ApiProxy.getCommonPageData().search_page_path + "?" + queryString
    }
  }

  static ezpointRatio = 
  {
    pointToCash: .1,
    priceToPoint: .02
  };

  static ezpointToCash(value: number)
  {
    return Math.floor(GS.ezpointRatio.pointToCash * value);
  }

  static priceToEzpoint(value: number)
  {
    return Math.round(GS.ezpointRatio.priceToPoint * value);
  }

  static SPORTS_VOUCHER_BONUS_RATE: number = .2;

  static getSportsVoucherBonus(amount: number)
  {
    return Math.ceil(amount * this.SPORTS_VOUCHER_BONUS_RATE);
  }

  static DATE_FORMAT: string = "YYYY-MM-DD";

  static DEFAULT_COUNTRY: string = "tw";

  static navSize = {
    0: {width: 0, height: 80},
    1: {width: 64, height: 0}
  };

  static init()
  {
    if (window.location.host === "local.savorks.com") {
      $.extend(this.setting, _localSetting);
    }
    else if (window.location.host === "www.hikaru.com.tw") {
      $.extend(this.setting, _testSetting);
    }

    ApiProxy.setLogSendingData(this.setting.logSendingData);
  }

  static getNavSize()
  {
    let i: StyleModes = ViewportRoot.index,
      v = this.navSize[i];
      
    return v === undefined ? this.navSize[StyleModes.default] : v;
  }
}
let Geom = {
  caculateContain: function (containerWidth: number, containerHeight: number, contentWidth: number, contentHeight: number)
  {
    var containerRatio = containerWidth / containerHeight,
      contentRatio = contentWidth / contentHeight,
      width, height;

    if (contentRatio > containerRatio) {
      width = containerWidth;
      height = width / contentRatio;
    }
    else {
      height = containerHeight;
      width = height * contentRatio;
    }

    return { width: width, height: height, ratio: width / contentWidth };
  },

  caculateCover: function (containerWidth: number, containerHeight: number, contentWidth: number, contentHeight: number)
  {
    var containerRatio = containerWidth / containerHeight,
      contentRatio = contentWidth / contentHeight,
      width, height;

    if (contentRatio > containerRatio) {
      height = containerHeight;
      width = height * contentRatio;
    }
    else {
      width = containerWidth;
      height = width / contentRatio;
    }

    var ratio = width / contentWidth;

    var tw = containerWidth / ratio,
      th = containerHeight / ratio;
    var contentCenterBound =
    {
      x: (contentWidth - tw) * .5,
      y: (contentHeight - th) * .5,
      width: tw,
      height: th
    };

    //console.log(contentCenterBound);

    return { width: width, height: height, ratio: ratio, contentCenterBound: contentCenterBound };
  }
};

export default Geom;
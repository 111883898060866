import { ComponentElem } from "@components/interfaces";
import PopupBase from "./PopupBase";
import Popups from "./Popups";
import { gsap } from "gsap";
import ViewportRoot from "@ts/sframe/ViewportRoot";
import Geom from "@ts/sframe/Geom";

export default class PhotoViewer extends PopupBase
{
  static instance: PhotoViewer;

  coverColor: string = `rgba(6, 7, 7, 0.2)`;

  dataList: any[];

  currentIndex: number;
  isImageOnLoading: boolean = false;

  $content: JQuery;
  $image: JQuery;
  $btnPrev: JQuery;
  $btnNext: JQuery;

  img: HTMLImageElement;

  constructor(elem: ComponentElem)
  {
    super(elem);

    PhotoViewer.instance = this;

    this.$content = this.$root.find(".content");
    this.$image = this.$root.find(".image");

    this.$btnPrev = this.$root.find(".btn-prev").on("click", (event: Event) =>
    {
      event.preventDefault();

      this.changeIndex(this.currentIndex - 1);
    });

    this.$btnNext = this.$root.find(".btn-next").on("click", (event: Event) =>
    {
      event.preventDefault();

      this.changeIndex(this.currentIndex + 1);
    });
  }


  showSinglePhoto(src: string)
  {
    let dataList = [{ "image_src": src }];
    this.showPhoto(0, dataList);
  }

  showPhoto(index: number, dataList: any[])
  {
    this.dataList = dataList;

    this.currentIndex = -1;
    this.updateDots();
    this.changeIndex(index);
    Popups.to("photo-viewer");
  }

  updateDots()
  {
    let $dotWrapper = this.$root.find(".wrapper").empty();
    if(this.dataList.length > 1)
    {      
      this.dataList.forEach((data, index)=>
      {
        let $dot = $(`<div class="dot"></dot>`);
        $dotWrapper.append($dot);
        $dot.on("click", (event:Event)=>
        {
          event.preventDefault();
          this.changeIndex(index);
        });
      });
    }
  }

  changeIndex(index: number)
  {
    if (this.isImageOnLoading) return;
    if (!this.dataList) return;
    if (index < 0) return;
    if (index >= this.dataList.length) return;
    if(index === this.currentIndex) return;

    this.currentIndex = index;

    this.$btnPrev[0].setAttribute("disabled", (index === 0) ? "true" : "false");
    this.$btnNext[0].setAttribute("disabled", (index >= (this.dataList.length - 1)) ? "true" : "false");

    if(this.dataList.length > 1)
    {
      let $dots = this.$root.find(".dot").attr("focused", "false");
      $dots[index].setAttribute("focused", "true");
    }

    this.changeImage();
  }

  changeImage()
  {
    gsap.killTweensOf(this.$image);
    gsap.set(this.$image, { opacity: 0 });

    this.isImageOnLoading = true;

    let img = this.img = new Image();
    img.onload = ()=>
    {
      this.isImageOnLoading = false;
      this.$image.css("background-image", "url(" + img.src + ")");
      this.updateSize();

    };

    img.onerror = ()=>
    {
      this.isImageOnLoading = false;
      this.img = null;
    }

    img.src = this.dataList[this.currentIndex]["image_src"];

    ViewportRoot.emitter.addListener("updated", ()=>
    {
      if(!this.isOnStage) return;
      this.updateSize();
    });
  }

  updateSize()
  {
    if (this.isImageOnLoading || !this.img) return;

    let bleedX = 70,
      bleedY = 40,
      maxWidth = ViewportRoot.width - bleedX * 2,
      maxHeight = ViewportRoot.height - bleedY * 2,
      bound = Geom.caculateContain(maxWidth, maxHeight, this.img.width, this.img.height);

    if (bound.ratio > 1) {
      bound.width = this.img.width;
      bound.height = this.img.height;
    }

    gsap.to(this.$content, { duration: .25, width: bound.width, height: bound.height });
    gsap.to(this.$image, { duration: .25, opacity: 1, delay: .25 });

  }
}
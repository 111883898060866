import ComponentBase from "@components/ComponentBase";
import { ComponentElem } from "@components/interfaces";
import { WorkThumbData } from "../index/Work";
import WorkThumb from "../ui/WorkThumb";
import ApiProxy from "../../ts/ApiProxy";
import WorkDetail from "./WorkDetail";

export default class MoreWorks extends ComponentBase
{
  private _dataList: WorkThumbData[];

  constructor(elem: ComponentElem)
  {
    super(elem);
    
    this._dataList = ApiProxy.getStoredData("index/get-work-list").list;
  }

  private _update(indexList: number[])
  {


    let self = this,
      $container = this.$root.find(".work-thumbs>.wrapper").empty();

    for (let k = 0; k < indexList.length; k++)
    {
      let workData = self._dataList[indexList[k]];
      
      let workThumb = WorkThumb.generate(workData);
      // workThumb.$root.attr("pc-col-size", self._colSize);

      workThumb.$root.on("click", (event:JQuery.ClickEvent)=>
      {
        event.preventDefault();
        // if(isLocking) return;
        // workThumb.toMyDetail();
        WorkDetail.toWork(workData);
      });

      // gsap.set(workThumb.$root, { opacity: 0, rotateX: 45, rotateZ: -15, x: -200, y: -100 });
      $container.append(workThumb.$root);
      // self._thumbList.push(workThumb);
    }
  }

  shuffle(avoidIndex: number)
  {
    const MaxAttempts = 100;

    let array = [],
      result = [],
      attempts = 0;

    for(let i=0;i<this._dataList.length;i++)
    {
      array.push(i);
    }
    
    // console.log(array.splice(2, 1));
    // console.log(array);
    
    
    while(result.length < 3)
    {
      attempts ++;
      if(attempts >= MaxAttempts) break;

      let index = parseInt(Math.random() * array.length),
        r = array.splice(index, 1)[0];
      if(r !== avoidIndex) result.push(r);
    }

    // console.log(result);
    this._update(result);
  }
}
import ComponentBase from "@root/components/ComponentBase";
import Popups from "../components/popups/Popups";
import GS from "./GlobalSetting";
import { gsap } from "gsap";
import { ComponentElem } from "@root/components/interfaces";
import { Common } from "@root/components/common/CommonTypes";
import { getViewportName } from "./sframe/ViewportRoot";
import EZAlert from "../components/popups/EZAlert";
import { ScrollListener } from "./sframe/ScrollListener";



let _customStyleElem: HTMLElement;

let Tools =
{
  init: () =>
  {
    window.EZ.Tools = Tools;

    if (!_customStyleElem) _customStyleElem = document.createElement('style');
    _customStyleElem.id = 'custom-page-style';
    $('head').append(_customStyleElem);
  },

  /**
   * 檢查是字串內容是否可以轉化為數字
   *
   * @param {string} str
   * @return {*}  {boolean}
   */
  isNumeric: (str: string): boolean => 
  {
    if (typeof str != "string") return false;
    ///@ts-ignore
    return !isNaN(str) && !isNaN(parseFloat(str));
  },

  stringReplaceBetween(s: string, start: number, length: number, replaceString: string)
  {
    return s.substring(0, start) + replaceString + s.substring(start + length);
  },

  combineName(firstName: string, surname: string): string
  {
    if (firstName) {
      if (firstName.match(/[\u4e00-\u9fa5]/g)) {
        return surname ?
          surname + firstName :
          firstName;
      }
      else {
        return surname ?
          firstName + " " + surname :
          firstName;
      }
    }
    else {
      return null;
    }
  },

  createLazyLoadImage(src: string)
  {
    let img = new Image();
    img.setAttribute("lazy-loading", "manual");
    img.onload = ()=>
    {
      img.setAttribute("lazy-loading", "done");
    };
    img.src = src;
    return img;
  },

  formatDateString(dateString: string, format: string = 'YYYY/MM/DD')
  {
    ///@ts-ignore
    let date = moment(dateString);
    return date.format(format);
  },

  getAvatarName(name: string)
  {
    name = name.replace(/  +/g, ' ');
    let length = 2;

    // let r = name.match(/\p{Script=Han}+|\p{Script=Latin}+/gu);
    // 符合中文的文字
    let r = name.match(/[\u4e00-\u9fa5]/g);
    console.log(r);

    if (r) {
      if (r.length === 2 || r.length === 3) {
        name = r[r.length - 2] + r[r.length - 1];
      }
      else {
        name = r[0];
        length = 1;
      }
    }
    else {
      // let array = name.match(/\p{Script=Han}+|\p{Script=Latin}+/gu);
      // console.log(array);

      name = name[0];
    }

    return name;

    // return `https://ui-avatars.com/api/?length=${length}&${name}`;
  },

  // generateAvatar(text: any, foregroundColor: any = "#ffffff", backgroundColor: any = "#000000")
  // {
  //   const canvas = document.createElement("canvas");
  //   const context = canvas.getContext("2d");

  //   canvas.width = 200;
  //   canvas.height = 200;

  //   // Draw background
  //   context.fillStyle = backgroundColor;
  //   context.fillRect(0, 0, canvas.width, canvas.height);

  //   // Draw text
  //   context.font = "bold 100px Assistant";
  //   context.fillStyle = foregroundColor;
  //   context.textAlign = "center";
  //   context.textBaseline = "middle";
  //   context.fillText(text, canvas.width / 2, canvas.height / 2);

  //   return canvas.toDataURL("image/png");
  // },

  completeLinks($container: any)
  {
    let $elems = $container.find('[popup-to]');
    $.each($elems, (index: number, elem: HTMLElement) =>
    {
      $(elem).on("click", (event: Event) =>
      {
        event.preventDefault();
        Popups.to(elem.getAttribute("popup-to"));
      });
    });

    $elems = $container.find(`[deeplink]`);
    $.each($elems, (index: number, elem: HTMLElement) =>
    {
      $(elem).on("click", (event: Event) =>
      {
        event.preventDefault();

        let target = elem.getAttribute("deeplink"),
          offsetY = GS.getNavSize().height;
        gsap.to(window, { duration: .5, scrollTo: { y: target, offsetY: offsetY } });
      });
    });
  },



  seekComponentByName(componentName: string, $elem?: JQuery): any
  {
    return Tools.seekComponent(`[component="${componentName}"]`, $elem);
  },

  seekComponentById(id: string, $elem?: JQuery): any
  {
    return Tools.seekComponent(`#${id}`, $elem);
  },

  seekComponentByClass(className: string, $elem?: JQuery): any
  {
    return Tools.seekComponent(`.${className}`, $elem);
  },

  seekComponentElem($elem: JQuery, componentName: string): ComponentElem
  {
    return (<ComponentElem>$elem.find(`[component="${componentName}"]`)[0]);
  },

  seekComponent(query: string, $elem?: JQuery): any
  {
    if (!$elem) $elem = $(`body`);
    return (<ComponentElem>$elem.find(query)[0])._component;
  },


  removeBetweenArrays(targetList: any[], sourceList: any[])
  {
    targetList.forEach((target: any) =>
    {
      sourceList.forEach((source, index, obj) =>
      {
        if (target === source) {
          obj.splice(index, 1);
        }
      });
    });
  },

  formatNumber(num: string | number)
  {
    if (typeof num === "string") {
      return parseInt(<string>num).toLocaleString("zh-tw");
    }
    else {
      return (<number>num).toLocaleString("zh-tw");
    }

  },

  testRectOverlap(rectA: DOMRect, rectB: DOMRect)
  {
    return (rectA.left < rectB.right && rectA.right > rectB.left &&
      rectA.top < rectB.bottom && rectA.bottom > rectB.top);
  },

  testRectOverlapY(rectA: DOMRect, rectB: DOMRect)
  {
    return (
      rectA.top < rectB.bottom && rectA.bottom > rectB.top);
  },

  addCustomStyle(query: string, styles: string, isMobile: boolean = false)
  {
    if (isMobile) _customStyleElem.innerHTML = _customStyleElem.innerHTML + "@media(max-width: 640px){" + query + "{" + styles + "}}" + '\n';
    else _customStyleElem.innerHTML = _customStyleElem.innerHTML + query + "{" + styles + "}" + '\n';
  },

  // 功能完整版
  addCustomStyle_2(id: string, query: string, styles: string, isMobile: boolean = false, isReplace: boolean = true)
  {
    let elem = $(`#${id}`)[0];

    if (!elem) {
      elem = document.createElement('style');
      elem.id = id;
      $('head').append(elem);
    }

    let newHtml = isMobile ? "@media(max-width: 640px){" + query + "{" + styles + "}}" + '\n' : query + "{" + styles + "}" + '\n';
    elem.innerHTML = isReplace ? newHtml : elem.innerHTML + newHtml;
  },


  /**
   * 捲動到目標 HTMLElement
   *
   * @param {HTMLElement} elem
   * @param {{mobile:number, pc:number}} [offset={mobile: 20, pc: 20}]
   */
  scrollToElement(elemOrId: HTMLElement | string, offset: { mobile: number, pc: number } | number = { mobile: 0, pc: 0 }): void
  {
    // console.log("x");
    
    let navHeight = GS.getNavSize().height,
      ///@ts-ignore
      basiceOffset = typeof offset === "number" ? offset : ((getViewportName() === "mobile" ? offset.mobile : offset.pc)),
      offsetY = navHeight + basiceOffset;    

    if (typeof elemOrId === "string") elemOrId = "#" + elemOrId;

    ///@ts-ignore
    let d = Math.abs($(elemOrId).offset().top - window.scrollY),
      speed = 2500,
      duration = d / speed;

    // duration = Math.min(1, duration);
    // duration = Math.max(.25, duration);
    
    // console.log(`d: ${d}, duration: ${duration}`);

    // let ease = blendEases('none', 'power1.out', 'power3.in');
    

    duration = .0;
    let ease = "linear";

    gsap.killTweensOf(window);
    gsap.to(window, { duration: duration, ease: ease, scrollTo: { y: elemOrId, offsetY: offsetY } });

    function blendEases(startEase: any, endEase: any, blender: any) {
      var s = gsap.parseEase(startEase),
          e = gsap.parseEase(endEase);
      blender = gsap.parseEase(blender || "power3.inOut");
      return function(v: any) {
        var b = blender(v);
        return s(v) * (1 - b) + e(v) * b;
      };
  }
  },

  listToDic(list: any[], key?: string)
  {
    let dic: any = {},
      obj: any;

    if (key) {
      for (let i = 0; i < list.length; i++) {
        obj = list[i];
        dic[obj[key]] = obj;
      }
    }
    else {
      for (let i = 0; i < list.length; i++) {
        obj = list[i];
        dic[obj] = obj;
      }
    }

    return dic;
  },

  /**
   * 轉換 json 內容為布林值
   *
   * @param {*} value
   * @return {*}  {boolean}
   */
  parseBoolean(value: any): boolean
  {
    if (value === true) return true;
    if (value === "true") return true;
    return false;
  },

  parseInt(value: any): number
  {
    return parseInt(value);
  },

  parseFloat(value: any): number
  {
    return parseFloat(value);
  },

  parseDate(value: string | moment.Moment, addSpace: boolean = true): string
  {
    /// @ts-ignore
    let date: moment.Moment = typeof value === "string" ? moment(value, GS.DATE_FORMAT) : value;
    let result = addSpace ? date.format("YYYY / MM / DD") : date.format("YYYY/MM/DD");
    return result;
  },

  parseValue(value: string, valueType: string)
  {
    let v: any = value;
    if (valueType === "int") v = Tools.parseInt(value);
    else if (valueType === "float") v = Tools.parseFloat(value);
    else if (valueType === "boolean") v = Tools.parseBoolean(value);
    else if (valueType === "gender") v = value === "male" ? "男" : "女";
    else if (valueType === "date") v = Tools.parseDate(value);
    else if (valueType === "date2") v = Tools.parseDate(value, false);

    return v;
  },

  updateValue($target: JQuery, valueName: string, value: string | number, valueType: string = "string")
  {
    let v: any = Tools.parseValue(String(value), valueType);

    $target.find(`[data-value="${valueName}"]`).text(v);
  },

  getFullName(firstName: string, surname: string)
  {
    let reg = /[^\u4e00-\u9fa5]/,
      isNotChinese = reg.test(firstName);

    return isNotChinese ?
      firstName + " " + surname :
      surname + "" + firstName;
  },

  /**
   * 取出 localStorage 資料 (資料以 json字串 儲存, 取出後將轉換為 json物件)
   * @param name 物件名稱
   * @returns 
   */
  getLocalStorageObj(name: string)
  {
    let objString: string = localStorage.getItem(name),
      obj: any;

    try {
      obj = JSON.parse(objString);
    }
    catch (e) { }

    if (!obj || typeof obj !== "object") obj = {};

    return obj;
  },

  /**
   * 儲存 localStorage 資料 (資料將被轉換成 json字串 後儲存)
   * @param name 物件名稱
   * @param obj 資料
   */
  saveLocalStorageObj(name: string, obj: any)
  {
    localStorage.setItem(name, JSON.stringify(obj));
  },

  isLocalStorageAvailable()
  {
    var test = 'test';
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (e) {
      EZAlert.alert("提示", `本網站需要使用瀏覽器的localStorage，未啟用將無法使用部分功能`);
      return false;
    }
  },

  shake(elem: HTMLElement)
  {
    let duration = .1,
      ease = "quad.inOut"

    gsap.to(elem, {
      duration: duration,
      x: -7,
      ease: ease
    });
    gsap.to(elem, {
      duration: duration,
      repeat: 4,
      x: 7,
      yoyo: true,
      delay: .1,
      ease: ease,
      clearProps: "transform"
    });
    // gsap.to(elem, {
    //   duration: duration,
    //   x: 0,
    //   delay: .1 * 4
    // });
  },

  // 限制 input 輸入字元
  setInputFilter(textbox: Element, inputFilter: (value: string) => boolean, errMsg: string): void {
    ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop", "focusout" ].forEach(function(event) {
      textbox.addEventListener(event, function(this: (HTMLInputElement | HTMLTextAreaElement) & { oldValue: string; oldSelectionStart: number | null, oldSelectionEnd: number | null }) {
        if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        }
        else if (Object.prototype.hasOwnProperty.call(this, "oldValue")) {
          this.value = this.oldValue;
          
          if (this.oldSelectionStart !== null &&
            this.oldSelectionEnd !== null) {
            this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
          }
        }
        else {
          this.value = "";
        }
      });
    });
  },

  setTimelineTrigger: (elem: HTMLElement | Window, tl: gsap.core.Timeline, startLabel: string = "start")=>
  {
    // tl.time(0, true).tweenTo(startLabel);
    tl.seek(startLabel).pause();

    let _isPaused = true;

    $(elem).on("click", (event:JQuery.ClickEvent)=>
    {
      event.preventDefault();
      _toggleAnimate();
    }); 

    function _toggleAnimate(isPaused: boolean = undefined)
    {
      if(isPaused === undefined)
      {
        isPaused = !_isPaused;
      }

      _isPaused = isPaused    

      if(_isPaused)
      {
        tl.time(0, true);
        tl.tweenTo(startLabel);
      }
      else
      {
        tl.restart();
      }
    }
  },

  splitText: function (dom: any, debug: boolean = false)
  {
    // gsap.set(dom, {perspective: 400});

    // console.log(dom);

    var string = dom.innerText;
    dom.innerHTML = "";

    //console.log(dom);
    //console.log(string);

    var i,
      char: string,
      charDom,
      array = [];

    for (i = 0; i < string.length; i++) {
      charDom = document.createElement("span");
      char = string.slice(i, i + 1);

      // console.log(`${char}: ${char.charCodeAt(0)}`);

      if (char === " ") {
        // charDom.innerHTML = "&nbsp;";
      }
      else if(char.charCodeAt(0) === 10)
      { 
        // if(debug) console.log("check");
        // charDom.innerHTML = `<br>`;
        charDom = document.createElement("br");
      }
      else {
        charDom.innerHTML = char;
      }

      $(charDom).css
        ({
          "display": "inline-block",
          "position": "relative"
        });

      dom.appendChild(charDom);

      array.push(charDom);
    }

    return array;
  },
};

export default Tools;
import error_schema from "@schema/error.json";
import rwd_image_schema from "@schema/rwd-image.json";

import Ajv, { JSONSchemaType } from "ajv";
import addFormats from "ajv-formats";


let _ajv: Ajv;

let AjvProxy =
{
  init()
  {
    _ajv = new Ajv({ coerceTypes: "array" });
    // _ajv.addSchema(error_schema);

    addFormats(_ajv, ["email"]);
    addFormats(_ajv, ["date"]);

    AjvProxy.addSchema(error_schema);
    AjvProxy.addSchema(rwd_image_schema);

    // SchemaTest.test();
  },

  addSchema(schema: any, name?: string)
  {
    if (!name) name = schema["$id"];



    if (!_ajv.getSchema(name)) {
      _ajv.addSchema(schema, name);
      // console.log("add " + name);
      // console.log(schema);
    }
  },

  validate<DataType>(schemaJson: any, jsonData: any, coerceTypes: any = "array"): { data: DataType, error?: any }
  { 
    _ajv.opts.coerceTypes = coerceTypes;    

    try {

      let schemaId: string = schemaJson["$id"];

      AjvProxy.addSchema(schemaJson, schemaId);
      let validate = _ajv.getSchema(schemaId);

      // console.log(validate.schema);
      // console.log("validae " + schemaId);

      if (validate(jsonData)) {
        return { data: jsonData as DataType };
      }
      else {
        console.error(`JSON Schema [${schemaId}] 驗證失敗:\n=> ` + validate.errors[0].message, { jsonData: jsonData, errors: validate.errors });
        console.dir(validate.errors[0]);

        return { error: { jsonData: jsonData, errors: validate.errors, message: `JSON Schema [${schemaId}] 驗證失敗` }, data: jsonData as DataType };
      }
    }
    catch (e) {
      console.error(e);
      return { error: e, data: null };
    }
  }
}

export default AjvProxy;
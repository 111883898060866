import PageManager from "../../PageManager";
import Tools from "../../ts/Tools";
import ViewportRoot from "../../ts/sframe/ViewportRoot";
import PhotoViewer from "../popups/PhotoViewer";
import Popups from "../popups/Popups";
import gsap from "gsap";


let $root: JQuery;

let _isInit = false,
  _isMenuOpen = false;

export default class Nav
{
  static init()
  {
    if(_isInit) return;
    _isInit = true;

    $root = $('nav');

    // $root.find(".menu-trigger").on("click", (event:JQuery.ClickEvent)=>
    // {
    //   event.preventDefault();
      
    //   // Popups.to("photo-viewer");
    //   PhotoViewer.instance.showSinglePhoto("./static/images/photo-examples/1.png");
      
    // });

    this._setupMenuTrigger();
    this._setupMenuButtons();
    this._presetAnimate();
  }

  private static _presetAnimate()
  {
    let q = gsap.utils.selector($root[0]);
    
    gsap.set(q(".ani"), {opacity: .0, filter: "blur(20px)"});
  }

  static play()
  {
    return new Promise((resolve)=>
    {
      let q = gsap.utils.selector($root[0]);

      let tl = gsap.timeline();
      tl.to(q(".ani"), {duration: .6, opacity: 1, filter: "blur(0px)", ease: "power1.in", stagger: .2});
      tl.add(()=>
      {
        $root.find(".border").attr("ready", "true");
      }, "-=.5");

      tl.add(()=>
      {
        resolve(true);
      }, "-=.25");

    });
  }

  private static _setupMenuButtons()
  {
    let $btns = $root.find(".btn");
    $btns.each((index, elem)=>
    {
      let $btn = $(elem),
        targetId = elem.getAttribute("target");
      
      $btn.on("click", (event:JQuery.ClickEvent)=>
      {
        event.preventDefault();

        this._setMenuOpen(false);

        if(PageManager.getCurrentPageName() !== "index")
        {
          PageManager.toPage("index").then(()=>
          {
            Tools.scrollToElement(targetId);
          });
        }
        else
        {
          Tools.scrollToElement(targetId);
        }
        
      });

    });
  }

  private static _setupMenuTrigger()
  {
    this._setMenuOpen(_isMenuOpen);
    
    let $navMenu = $root.find(".nav-menu");
    $navMenu.on("click", (event:JQuery.ClickEvent)=>
    {
      event.preventDefault();
      if(event.target === $navMenu[0]) this._setMenuOpen(false);
    });
    
    $root.find(".menu-trigger").on("click", (event:JQuery.ClickEvent)=>
    {
      event.preventDefault();
      
      this._setMenuOpen(!_isMenuOpen);
    });
  }

  private static _setMenuOpen(b: boolean)
  {
    _isMenuOpen = b;
    $root.attr("is-open", _isMenuOpen? "true": "false");
  }

  static isInit() { return _isInit; }

  static resize(scale: number = 1)
  {
    if(scale === 1)
    {
      $root.css({
        "transform": "",
        "transformOrigin": ""
      })
    }
    else
    {
      $root.css({
        "transform": `scale(${scale})`,
        "transformOrigin": "0 0"
      })

    }
  }
}
import PageRoot from "@/src/scripts/components/common/PageRoot";
import Components from "@components/Compoents";
import Popups from "@components/popups/Popups";
import ApiProxy from "@ts/ApiProxy";
import GS from "@ts/GlobalSetting";
import { ScrollListener } from "@ts/sframe/ScrollListener";
import Utility from "@ts/sframe/Utility";
import ViewportRoot from "@ts/sframe/ViewportRoot";
import Tools from "@ts/Tools";

import { gsap } from "gsap";
import { ScrollToPlugin, ScrollTrigger, PixiPlugin } from "gsap/all";
import AjvProxy from "./ts/AjvProxy";
// import work_schema from "@schema/index/get-work-list.json";
import PageManager from "./PageManager";
import WorkDetail from "./components/work-detail/WorkDetail";

import Loading from "./ts/Loading";
import Nav from "./components/common/Nav";

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, PixiPlugin);


const self: any =
{
  isLayoutOn: false,

  init: ()=>
  {
    // self.build();

    GS.init();    
    PageManager.init();

    let prefix = GS.setting.testEnv? "": "/";
    $(`#shared-popups`).load(prefix + "shared-popups.html", (responseTxt, statusTxt, xhr)=>
    {
      Loading.updateDataProgress(.2);

      PageManager.loadPage("work-detail", prefix + "work-detail.html").then(()=>
      {
        // console.log(PageManager.getPageRoot("work-detail").html());

        Loading.updateDataProgress(.5);
        
        self.initApi();

        loadData().then(()=>
        { 
          Loading.updateDataProgress(.8);

          self.build(); 
        });
      });
    });
  },

  initApi()
  {
    AjvProxy.init();

    ApiProxy.setApiPath(GS.setting.apiPath);
    ApiProxy.setApiExtension(GS.setting.apiExt);
    if(GS.setting.testEnv)
    {
      ApiProxy.setSimplateDelay(0);
    }
  },

  build: function ()
  { 

    if(Utility.urlParams.marker === "1") $("body").attr("marker", "true");

    window.EZ = {};

    // ///@ts-ignore
    // if (window._API_DATA_) {
    //   ///@ts-ignore
    //   ApiProxy.applyTestData(window._API_DATA_);
    // }

    Tools.init();
    Tools.completeLinks($('body'));

    if (Utility.urlParams.layout) {
      $("body").toggleClass("layout-mode", true);
      if (Utility.urlParams.popup) {
        $("body").toggleClass("popup-layout-mode", true);
      }
      self.isLayoutOn = true;

      if(Utility.urlParams["layout-only"] === "true")
      {
        $("body").attr("layout-only", "true");
      }
    }

    window.addEventListener("resize", () =>
    {
      ViewportRoot.update();
      ScrollTrigger.refresh();
      
    });

    ViewportRoot.update();

    if(self.preInitPage) self.preInitPage();    

    
    
    Components.init();

    PageRoot.init(false);
    Popups.init();

    WorkDetail.init();

    applySectionLayout();

    if (self.initPage) self.initPage();

    ScrollListener.active();
    ViewportRoot.emitter.addListener("updated", ()=>
    {
      ScrollListener.update();
      
      if (self.isLayoutOn && ViewportRoot.changed) {
        var ext = (ViewportRoot.index == 0) ? ".m.jpg" : ".jpg";
        $("#layout").html("<img src='./assets/images/layout/" + Utility.urlParams.layout + ext + "'>");
      }
    });

    
    ViewportRoot.update();

    PageManager.emitter.on("beforePageTween", ()=>
    {
      ScrollTrigger.refresh();
    })

    
    ScrollTrigger.refresh();

    Loading.updateDataProgress(1);

    if(Utility.urlParams.test === "skip-loading")
    {
      $("#loading").detach();
      Nav.play();
      $('[lazy-loading="pending"]').attr("lazy-loading", "done");
      if(self.ready) self.ready.call(null);
    }
    else
    {

      gsap.to("#loading", {duration: .2, delay: .5, ease: "power1.in", opacity: 0, onComplete: ()=>
      {
        $("#loading").detach();
        Nav.play().then(()=>
        {
          if(self.ready) self.ready.call(null);
          $('[lazy-loading="pending"]').attr("lazy-loading", "done");
        });
      }});
    }

      // WorkDetail.toIndex(0);
    
      

  },
  preInitPage: null,
  initPage: null,
  ready: null
};

window.addEventListener('load', () =>
{
  self.init();
});

function applySectionLayout()
{
  let sectionLayout = Utility.urlParams["section-layout"];
  if(sectionLayout)
  { 
    let elem = document.createElement("div");
    elem.style.position = "absolute";
    elem.style.width = "100%";
    elem.style.height = "100%";
    elem.style.opacity = ".5";
    elem.style.backgroundRepeat = "no-repeat";

    let ext = (window.innerWidth <= 640)? ".m.jpg": ".jpg";
    elem.style.backgroundImage = `url(./assets/images/layout/${sectionLayout}${ext}`;

    // let $container = sectionLayout === "footer"? $("footer"): $(`section[component="${sectionLayout}"]`);
    let $container = $(`#${sectionLayout}`);
    $container.prepend(elem);
    $container.find(">.content").css("opacity", ".5");
  }
}

function loadData() 
{
  return new Promise((resolve)=>
  {
    callApi("index/get-work-list").then(()=>
    {
      return callApi("index/get-client-list");
    }).then(()=>
    {
      return callApi("index/get-team-member-list");
    // }).then(()=>
    // {
    //   return callApi("work-detail/work-1");
    }).then(()=>
    {
      resolve(true);
    });
  }); 
} 

function callApi(apiName: string, schema?: any) 
{
  return new Promise((resolve)=>
  {
    ApiProxy.callApi(apiName, {}, { schema: schema, useApiSchemaData: false }).then((res) =>
    {
      ApiProxy.storeData(apiName, res.data);
      resolve(true);
  
    }).catch((error) =>
    {
      console.log(error);
    });
  });
}




export default self;

console.log("<entry.main.ts>");

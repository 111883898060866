import ComponentBase from "@components/ComponentBase";
import { ComponentElem } from "@components/interfaces";
import { WorkThumbData } from "../index/Work";
import Tools from "../../ts/Tools";
import Components from "../Compoents";
import RwdPicture from "../RwdPicture";
import PageManager from "../../PageManager";
import WorkDetail from "../work-detail/WorkDetail";

export default class WorkThumb extends ComponentBase
{
  static generate(data: WorkThumbData)
  {
    return new WorkThumb(WorkThumb.createElem(), data);
  }

  private _data: WorkThumbData;

  constructor(elem: ComponentElem, data?: WorkThumbData)
  {
    super(elem);
    
    if(data)
    {
      this._data = data;
      this.$root.find(".title").text(data.title);
      this.$root.find(".description").text(data.description);

      let $hashtags = this.$root.find(".hashtags").empty();

      data.hashtag_list.forEach(tagName => 
      {        
        $hashtags.append($(`<div class="tag">${tagName}</div>`));
      });

      Components.bindScripts(elem);
      
      let rp = Tools.seekComponentByClass("image", this.$root) as RwdPicture;
      rp.setDataSrc(data.image.pc, "pc");
      rp.setDataSrc(data.image.mobile, "mobile");
      rp.setAlt(data.image.alt);

      rp.load();
    }
  }

  toMyDetail()
  {
    // PageManager.toPage("work-detail");
    WorkDetail.toWork(this._data);
  }
}
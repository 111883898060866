// import { Utility } from "./sframe/Utility";

import { Common } from "@components/common/CommonTypes";
import EZAlert from "@root/components/popups/EZAlert";

import GS from "./GlobalSetting";
import AjvProxy from "@root/ts/AjvProxy";

let _storedData: any = {};

let _testData: any,
  _apiResponseAfterfixs: any = {};

let _apiExtension = "",
  _apiPath = "./api/",
  _method = "POST",
  _alwaysCompleteWithError = true,
  _useTestDataIfExist = true,
  _simulateDelay = 1,
  _dataType = "json",
  _logSendingData = false;

let _token: string = "";

export interface ApiProxyOptions
{
  testDataName?: string | boolean;
  method?: string;
  async?: boolean;
  completeWithError?: boolean;
  sendAsJsonText?: boolean;
  simDelay?: number;
  schema?: any;
  exampleIndex?: number;
  userPending?: boolean;
  useApiSchemaData?: boolean; // 是否使用 schema 中的 example 作為回應結果 (若無設定將使用 GS 中的全域設定)
}

let _fetchedPageData: any = {};

let ApiProxy = {

  getCommonPageData(): Common.CommonPageData
  {
    return ApiProxy.getPageData("common-page-data");
  },

  // getPageData(): any
  // {
  //   return ApiProxy.getDataFromHtml("page-data");
  // },

  // convertPageData(id: string, converter?: any): any
  // {
  //   if (_fetchedPageData[id]) return _fetchedPageData[id];
  //   _fetchedPageData[id] = converter? converter(ApiProxy.getPageDataString(id)): JSON.parse(ApiProxy.getPageDataString(id));
  //   return _fetchedPageData[id];
  // },

  getPageDataString(id: string): string
  {
    let elem = document.getElementById(id);
    if(!elem) return null;

    let jsonString = elem.innerHTML as string;
    jsonString = jsonString.replace(/\\"|"(?:\\"|[^"])*"|(\/\/.*|\/\*[\s\S]*?\*\/)/g, (m, g) => g ? "" : m);
    return jsonString;
  },

  validatePageData(id: string = "page-data", schema?: any)
  {
    let string = ApiProxy.getPageDataString(id);
    let obj = JSON.parse(string);
    let result = AjvProxy.validate(schema, obj);
    if (!result.error) {
      obj = result.data;
    }

    _fetchedPageData[id] = obj;

    return _fetchedPageData[id];
  },

  getPageData(id: string = "page-data")
  {
    if (_fetchedPageData[id]) return _fetchedPageData[id];
    _fetchedPageData[id] = JSON.parse(ApiProxy.getPageDataString(id));

    // console.log(_fetchedPageData[id]);

    return _fetchedPageData[id];
  },

  logPageData(id: string = "page-data")
  {
    let data = ApiProxy.getPageData(id);
    data = JSON.parse(JSON.stringify(data)); // 快照物件當前狀態, 否則 console log 出的資料可能會後被後來的程式變動過
    console.log(data);
  },

  /**
   * 快速呼叫 api
   * @param apiName 
   * @param params 
   * @param autoClosePending 
   * @param options 
   * @returns 
   */
  quickCall: (apiName: string, params: any, autoClosePending: boolean = true, options?: ApiProxyOptions): Promise<any> =>
  {
    return new Promise((resolve) =>
    {
      EZAlert.pending();

      // let op = Object.assign({ simDelay: 800 }, options);
      let op = Object.assign({}, options);

      ApiProxy.callApi(apiName, params, op).then((result) =>
      {
        (autoClosePending) ? EZAlert.pending(false, () => { resolve(result) }) : resolve(result);
        // if(autoClosePending) EZAlert.pending(false);
        // resolve(result);

      }).catch((error: any) =>
      {
        console.error(error);
        EZAlert.pending(false);
        EZAlert.alert(error.message);
      });
    });
  },

  /**
   * 快速呼叫 api (不使用 pending 畫面)
   * @param apiName 
   * @param params 
   * @param options 
   * @returns 
   */
  quickCall2: (apiName: string, params: any, options?: ApiProxyOptions): Promise<any> =>
  {
    return new Promise((resolve) =>
    {
      let op = Object.assign({}, options);

      ApiProxy.callApi(apiName, params, op).then((result) =>
      {
        resolve(result);

      }).catch((error: any) =>
      {
        console.error(error);
        EZAlert.alert(error.message);
      });
    });
  },

  callApi: function (apiName: string, params: any = {}, options: ApiProxyOptions = undefined): Promise<any>
  {
    return new Promise((resolve, reject) =>
    {
      if (params && _logSendingData) {
        console.log("=== API[" + apiName + "] sending ===");
        console.log(params);
      }

      options = Object.assign({ simDelay: _simulateDelay }, options);

      var testDataName = options.testDataName,
        method = options.method || _method,
        async = options.async === undefined ? true : options.async === undefined,
        completeWithError = options.completeWithError || _alwaysCompleteWithError;

      if (testDataName === undefined) {
        testDataName = _useTestDataIfExist;
      }

      if (testDataName !== false) {
        if (!testDataName || testDataName === true) testDataName = apiName;
      }

      if (options.schema) {
        let sendSchema = options.schema.properties.send;
        sendSchema["$id"] = "/" + apiName + ".send";

        if (options.schema.definitions) sendSchema.definitions = options.schema.definitions;

        let validatedResult = AjvProxy.validate(sendSchema, params, false);
        if (validatedResult.error) {
          reject(validatedResult.error);
          return;
        }
      }      

      if (_testData && testDataName && _testData[testDataName]) {

        ApiProxy.applyTestSendData(apiName, params);

        var response = _testData[testDataName].response;

        if (!response) {
          console.error("[" + testDataName + "] not exist in test data");
        }
        else {
          (options.simDelay === 0) ? complete(response) : setTimeout(function () { complete(response); }, options.simDelay);
        }
      }
      else if (GS.setting.useApiSchemaData && options.useApiSchemaData !== false && options.schema && options.schema.properties && options.schema.properties.response && options.schema.properties.response.examples) {

        let exampleIndex = options.exampleIndex || 0,
          response = options.schema.properties.response.examples[exampleIndex];

        (options.simDelay === 0) ? complete(response) : setTimeout(function () { complete(response); }, options.simDelay);
      }
      else {
        (options.simDelay === 0) ? sendApi() : setTimeout(function () { sendApi(); }, options.simDelay);
      }

      function sendApi()
      {
        let afterFix = getAfterfix(apiName),
          apiUrl = _apiPath + apiName + afterFix + _apiExtension;

        let sendingObj: any =
        {
          url: apiUrl,
          crossDomain: true,
          type: method,
          async: async,
          dataType: _dataType,

          // headers: {"Authorization": _token},

          data: JSON.stringify(params),
          contentType: 'application/json'
        };

        $.ajax
          (sendingObj)
          .done(complete)
          .fail((event) =>
          {
            if (!async) return;

            console.log("API: [" + apiName + "] 無法取得伺服器回應");
            console.log(event);

            if (completeWithError) {
              reject({ message: "API: [" + apiName + "] 無法取得伺服器回應" });
            }
          });

      }

      function complete(response: any)
      {
        //if(!_cachedData[apiName]) _cachedData[apiName] = response;

        // console.log(response);        

        if (options.schema) {
          let responseSchema = options.schema.properties.response;
          responseSchema["$id"] = "/" + apiName + ".response";

          if (options.schema.definitions) responseSchema.definitions = options.schema.definitions;

          let validatedResult = AjvProxy.validate(responseSchema, response);
          if (validatedResult.error) {
            reject(validatedResult.error);
            return;
          }
        }


        if (response.error) {

          if (typeof response.error === "string") response.error = { message: response.error };
          
          console.log("api [" + apiName + "] error: " + response.error.message);

          if (completeWithError) {
            reject(response.error);
          }
        }
        else {
          resolve(response);
        }
      }
    });
  },

  applyTestData: function (testData: any)
  {
    _testData = testData;
  },

  getApiPath: function ()
  {
    return _apiPath;
  },

  setApiPath: function (path: string)
  {
    _apiPath = path;
  },

  setApiExtension: function (ext: string)
  {
    _apiExtension = ext;
  },

  setMethod: function (method: string)
  {
    _method = method;
  },

  getMethod: function ()
  {
    return _method;
  },

  setToken(token: string)
  {
    _token = "Bearer " + token;
  },

  setLogSendingData: function (b: boolean)
  {
    _logSendingData = b;
  },

  setSimplateDelay: (delay: number) =>
  {
    _simulateDelay = delay;
  },

  /**
   * 改變測試 api 回應的資料
   *
   * @param {string} apiName
   * @param {*} params
   */
  resetTestData: (apiName: string, params: any) =>
  {
    if (_testData && _testData[apiName] && _testData[apiName].reset) {
      _testData[apiName].reset(params);
    }
  },

  /**
   * 依據 sendData 改變測試 api 回應的資料
   *
   * @param {string} apiName
   * @param {*} sendData
   */
  applyTestSendData: (apiName: string, sendData: any) =>
  {
    if (_testData && _testData[apiName] && _testData[apiName].applySendData) {
      _testData[apiName].applySendData(sendData);
    }
  },

  storeData(apiName: string, data: any)
  {
    _storedData[apiName] = data;
  },

  getStoredData(apiName: string)
  {
    return _storedData[apiName];
  },

  setApiResponseAfterfix(apiName: string, afterFix: string)
  {
    _apiResponseAfterfixs[apiName] = "." + afterFix;
  }
};

function getAfterfix(apiName: string)
{
  let s = _apiResponseAfterfixs[apiName] ? _apiResponseAfterfixs[apiName] : ".response";
  return GS.setting.useApiSchemaData ? s : "";
}

export default ApiProxy;